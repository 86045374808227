import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const createOrganization = async ({ name }) => {
  const url = "/organizations";

  const response = await backendService.post({
    url,
    data: {
      name,
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const editOrganization = async ({ organizationId, name = null, timeZone = null }) => {
  const url = `organizations/${organizationId}`;

  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      name,
      timeZone,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getCurrentSubscription = async ({ organizationId }) => {
  const url = `organizations/${organizationId}/subscription`;
  const response = await backendService.get({
    url,
    data: fromCamelToSnake({
      organizationId,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const getOrganizationUsers = async ({ organizationId }) => {
  const url = `organizations/${organizationId}/users`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const getUserInvitations = async ({ organizationId }) => {
  const url = `organizations/${organizationId}/users/invitations`;
  const response = await backendService.get({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const destroyUserInvitation = async ({ organizationId, userInvitationId }) => {
  const url = `organizations/${organizationId}/users/invitations/${userInvitationId}`;
  const response = await backendService.destroy({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const inviteOrganizationUser = async ({ organizationId, emailAddress, role }) => {
  const url = `organizations/${organizationId}/users`;
  const response = await backendService.post({
    url,
    data: fromCamelToSnake({
      emailAddress,
      role,
    }),
  });
  return { data: fromSnakeToCamel(response.data) };
};

export const editOrganizationUser = async ({ organizationId, organizationUserId, role }) => {
  const url = `organizations/${organizationId}/users/${organizationUserId}`;
  const response = await backendService.patch({
    url,
    data: fromCamelToSnake({
      role,
    }),
  });
  return { data: fromSnakeToCamel(response.data) };
};

export const removeOrganizationUser = async ({ organizationId, organizationUserId }) => {
  const url = `organizations/${organizationId}/users/${organizationUserId}`;
  const response = await backendService.destroy({ url });
  return { data: fromSnakeToCamel(response.data) };
};

export const initDeleteOrganization = async ({ organizationId, sendEmail = true } = {}) => {
  const url = `/organizations/${organizationId}/init-delete`;

  const response = await backendService.post({ url, data: fromCamelToSnake({ sendEmail }) });
  return { data: fromSnakeToCamel(response.data) };
};

export const deleteOrganization = async ({ organizationId, destroyCode }) => {
  const url = `/organizations/${organizationId}/delete`;

  const response = await backendService.post({ url, data: fromCamelToSnake({ destroyCode }) });
  return { data: fromSnakeToCamel(response.data) };
};
